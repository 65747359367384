
var slideshows = document.querySelectorAll('.swiper-container');
_.each(slideshows, function(slideshow){
    var _this = this;
    var settings = {
        loop: true,
        autoplay: 3500,
        speed: 2000,
        autoplayDisableOnInteraction: false,
        effect: 'fade',
        observer: true,
        observerParents: true,
        fade: {
          crossFade: true
        },
    };
    var swiper = new Swiper('.swiper-container', settings);
});


var p = new Parallax('.parallax', {
  offsetYBounds: 50,
  intensity: 30,
  center: 0.5,
  safeHeight: 0.15
}).init();

function aspotSize(){
  var header = document.querySelector('.primary-header');
  var aspots = document.querySelectorAll('.aspot');
  for (var i = 0; i < aspots.length; i++) {
    if(aspots[i].classList.contains('y5')){
      header.classList.add('y5');
      header.classList.add('md_y8');
    } else if(aspots[i].classList.contains('y6')){
      header.classList.add('y6');
      header.classList.add('md_y6');
    }
  }
}
aspotSize();