var windowWidth = window.innerWidth;
var windowHeight = window.innerHeight;
var documentHeight = document.body.offsetHeight;
var onSmall = [], onMedium = [], onLarge = [];
var screenSize = {
	small: 0,
	medium: 900,
	large: 1200,
};



// ----------------------------------------------------------------

var classList = document.body.classList;

// ----------------------------------------------------------------

var windowCheck = function(){
	windowWidth = window.innerWidth;
	if (windowWidth > screenSize.small && windowWidth < screenSize.medium) {
		classList.add('isSmall');
		classList.remove('isMedium');
		classList.remove('isLarge');
		for (i = 0; i < onSmall.length; i++) {
		    onSmall[i]();
		}
	} else if (windowWidth > screenSize.medium && windowWidth < screenSize.large) {
		classList.add('isMedium');
		classList.remove('isSmall');
		classList.remove('isLarge');
		for (i = 0; i < onMedium.length; i++) {
		    onMedium[i]();
		}
	} else if (windowWidth > screenSize.large) {
		classList.add('isLarge');
		classList.remove('isSmall');
		classList.remove('isMedium');
		for (i = 0; i < onLarge.length; i++) {
		    onLarge[i]();
		}
	}
};

window.onload = windowCheck();
window.addEventListener("resize", _.debounce(windowCheck, 10));

var breakpoint = {
   small: document.body.classList.contains('isSmall')
};
