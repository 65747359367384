var main = {
	selector: document.querySelector('.main'),
	active: 'main-active'
};

var	primaryHeader = {
	selector: document.querySelector('.primary-header'),
	active: 'primary-header-active'
};


var primaryNav = {
	class: 'primary-nav',
	selector: document.querySelector('.primary-nav'),
	icon: document.querySelector('.primary-nav-icon'),
	trigger: document.querySelector('.trigger-primary-nav'),
	activeClass: 'primary-nav-is-active',
	mobileClass: 'primary-nav-is-mobile',
	toggleActive: function(){
		if(classList.contains(this.activeClass)){
			classList.remove(this.activeClass);
			primaryNavIcon.toggleActive();
		} else {
			classList.add(this.activeClass);
			primaryNavIcon.toggleActive();
		}
	},
	toggleMobile: function(){
		if(this.trigger.offsetHeight > 0 ){
			classList.add(this.mobileClass);
		} else {
			classList.remove(this.mobileClass);
		}
	}
};

var primaryNavIcon = {
	class: 'primary-nav-icon',
	activeClass: 'primary-nav-icon-is-active',
	toggleActive: function(){
		if(classList.contains(this.activeClass)){
			classList.remove(this.activeClass);
		} else {
			classList.add(this.activeClass);
		}
	}
};

primaryNav.trigger.onclick = function(e){
	e.preventDefault();
	primaryNav.toggleActive();
	e.stopPropagation();
};




